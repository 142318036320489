import React from 'react';
import { injectIntl } from 'react-intl';

export const InjectI18nContext = React.createContext({});

class InjectI18n extends React.Component {
  render() {
    const { intl } = this.props;

    const formatMessage = () => {
      console.error(
        '`formatMessage` is not a function. Use `translate` instead.',
      );
    };

    const translate = (descriptor, values) => {
      const { id = 'idNotSpecified', defaultText } = descriptor;

      if (!defaultText) {
        console.error(
          'You must create the English content by providing `translate` a `defaultText` value in the descriptor argument',
        );
        return null;
      }

      return intl.formatMessage(
        { id, defaultMessage: defaultText, ...descriptor },
        values,
      );
    };

    return (
      <InjectI18nContext.Provider value={{ ...intl, formatMessage, translate }}>
        {this.props.children}
      </InjectI18nContext.Provider>
    );
  }
}

export default injectIntl(InjectI18n);
