/*
  Replacement for lodash's _.get() function.
  Pass in a function that returns the value you want to get.
  Optionally, pass in a default value.
*/
export const get = (func, defaultValue) => {
  try {
    const returnValue = func();
    return returnValue == null ? defaultValue : returnValue;
  } catch (_error) {
    return defaultValue;
  }
};
