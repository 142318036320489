import React from 'react';
import { FormattedMessage } from 'react-intl';

const Translate = ({ id = 'idNotSpecified', defaultText, ...props }) => {
  if (!defaultText) {
    console.error(
      'You must provide <Translate /> a `defaultText` for English content',
    );
    return null;
  }

  return <FormattedMessage {...props} id={id} defaultMessage={defaultText} />;
};

export default Translate;
