const FIVE_SECONDS = 5000;

const LOCALIZATION_S3_URL =
  process.env === 'production'
    ? 'https://i18n.podium.com/translations'
    : 'https://webchat-cats-dev.s3.amazonaws.com';

/*
    Takes 2 arguments:
    1. Namespace (e.g., "demogorgon")
    2. Locale (e.g., "en", "fr")
    */
const requestLanguageFile = (namespace, locale) => {
  const url = `${LOCALIZATION_S3_URL}/${namespace}_${locale}.json`;

  return new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');

    xhr.timeout = FIVE_SECONDS;
    xhr.ontimeout = error => {
      console.error(`Timeout requesting localization at ${url}: `, error);
      reject(xhr.responseText);
    };

    xhr.send();

    // Both errors and successful calls will make it into the `onload` function
    xhr.onload = () => {
      try {
        resolve(JSON.parse(xhr.responseText));
      } catch (error) {
        console.error(`Error requesting localization at ${url}: `, error);
        reject(xhr.responseText);
      }
    };
  });
};

export default requestLanguageFile;
