import React from 'react';
import { IntlProvider } from 'react-intl';

import requestLanguageFile from '../requestLanguageFile';
import InjectI18n from './InjectI18n.jsx';
import { get } from '../util/get';

class I18nProvider extends React.Component {
  state = { messages: {}, languageFileHasLoaded: false };

  /*
    The `locale` prop is not required and can be determined from the
    browser setting; in that case, the language alone will be used
    without the region (e.g., "en" rather than "en-US"). Alternatively,
    a locale code can be passed in and will be used as is.
  */
  language = () => {
    return (
      get(() => this.props.locale) ||
      get(() => window.navigator.language.slice(0, 2)) ||
      'en'
    );
  };

  onLanguageFileLoaded = messages => {
    const { onLoad } = this.props;

    const stateUpdate = messages
      ? { messages, languageFileHasLoaded: true }
      : { languageFileHasLoaded: true };

    this.setState(stateUpdate, () => {
      onLoad && onLoad();
    });
  };

  componentDidMount() {
    const { supportedForeignLanguages, namespace } = this.props;
    const language = this.language();

    if (!supportedForeignLanguages) {
      console.error(
        'You must I18nProvider provide a `supportedForeignLanguages` array of locales',
      );
    } else if (
      supportedForeignLanguages.includes(language) &&
      language !== 'en'
    ) {
      requestLanguageFile(namespace, language)
        .then(messages => {
          this.onLanguageFileLoaded(messages);
        })
        .catch(error => {
          console.error('Error occurred when requesting language file', error);
          this.onLanguageFileLoaded();
        });
    } else this.onLanguageFileLoaded();
  }

  render() {
    const {
      children,
      namespace,
      onError: customOnError,
      messages: _messages,
      ...props
    } = this.props;
    const { languageFileHasLoaded, messages } = this.state;

    const onError = customOnError || (e => console.warn(e));

    if (_messages) {
      console.warn(
        'Overriding `messages` prop for I18nProvider is not supported',
      );
    }
    if (!namespace) {
      console.error(
        'You must provide I18nProvider a `namespace` to indicate how the localized files are named (e.g., namespace is "demogorgon" for demogorgon_xx.json)',
      );
      return null;
    }
    if (!languageFileHasLoaded) {
      return null;
    }

    return (
      <IntlProvider
        locale={this.language()}
        key={this.language()}
        messages={messages}
        onError={onError}
        {...props}
      >
        <InjectI18n>{children}</InjectI18n>
      </IntlProvider>
    );
  }
}

export default I18nProvider;
